<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-form-group class="mr-1 mb-md-0">
          <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="ClearVal()"
          >
            <feather-icon
              icon="PlusIcon"
              size="12"
            />
            เพิ่มประเภทหวย
          </b-button>
        </b-form-group>
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative items-center"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
    >
      <template #cell(index)="data">
        {{ perPage * (currentPage - 1) + (data.index + 1) }}
      </template>
      <template #cell(picture)="data">
        <b-avatar
          size="lg"
          :src="data.item.picture"
        />
      </template>
      <template #cell(subtype)="data">
        <b-button
          v-if="data.item.subtype === 1"
          size="sm"
          variant="outline-primary"
          @click="DetailSub(data.item.Subhead)"
        >
          ดูรายละเอียด
        </b-button>
      </template>

      <template #cell(picture)="data">
        <img
          v-if="data.item.picture"
          :src="`https://api.hapi-lot.com/api/get/img?pathfile=${EncodeBase64(data.item.picture)}`"
          style="max-width: 100%; max-height: 30px;"
        >
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="updateData(data.item)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteData(data.item.id)"
          />
          <b-tooltip
            title="Preview Invoice"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <!-- Detail -->
    <b-modal
      v-model="modalSubtype"
      title="SUB TYPE"
      ok-title="close"
      ok-only
      ok-variant="outline-primary"
    >
      <b-card-text>
        <h3 class="text-center">
          ชื่อประเภทหวยย่อย
        </h3>
        <p
          v-for="(item, index) in SubDetail"
          :key="item.id"
        >
          {{ index + 1 + '. ' + item.name }}
        </p>
      </b-card-text>
    </b-modal>

    <b-modal
      v-model="modalShow"
      title="เพิ่มประเภทหวย"
      hide-footer
    >
      <b-form-group
        label="กลุ่มหวย"
        label-for="vi-first-name0"
      >
        <v-select
          id="vi-first-name0"
          v-model="Data.group_id"
          transition=""
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="rolelist"
          placeholder="เลือกกลุ่มหวย"
          :reduce="text => text.id"
        />
      </b-form-group>
      <b-form-group
        label="ชื่อประเภทหวย"
        label-for="vi-first-name1"
      >
        <b-form-input
          id="vi-first-name1"
          v-model="Data.name"
          placeholder="ระบุประเภทหวย"
        />
      </b-form-group>
      <b-form-group label="รูปภาพ">
        <div
          v-if="!imagePreview"
          class="upload-btn d-flex justify-content-center align-items-center"
          @click="openImageInput"
        >
          <input
            ref="imageInput"
            type="file"
            accept=".jpg,.jpeg,.png,.gif"
            style="display: none;"
            @change="handleFileUpload"
          >
          <span>เลือกรูปภาพ</span>
        </div>
        <div
          v-if="imagePreview"
          class="upload-btn d-flex justify-content-center align-items-center"
        >
          <img
            :src="imagePreview"
            style="max-width: 100%; max-height: 120px;"
          >
          <button
            class="clear-btn"
            @click="clearImage"
          >
            <feather-icon
              icon="XIcon"
              size="13"
            />
          </button>
        </div>

      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          v-model="Data.subtype"
          :checked="Data.subtype"
          :value="1"
          :unchecked-value="0"
        >
          Sub type
        </b-form-checkbox>
      </b-form-group>
      <div v-if="Data.subtype === 1">
        <div>
          <!-- Row Loop -->
          <b-row
            v-for="(item, index) in Data.Subhead"
            :key="item.id"
          >
            <!-- Item Name -->
            <b-col md="9">
              <b-form-group
                label="ชื่อประเภทหวยย่อย"
                label-for="vi-first-name1"
              >
                <b-form-input
                  id="vi-first-name1"
                  v-model="item.name"
                  placeholder="ระบุประเภทหวยย่อย"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              lg="3"
              md="3"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="deleteSubData(item.id, index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>ลบ</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain()"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add New</span>
        </b-button>
      </div>
      <div class="text-center">
        <b-button
          v-if="Data.id"
          variant="gradient-warning"
          class="mr-1"
          @click="saveData()"
        >
          อัพเดท
        </b-button>
        <b-button
          v-else
          variant="gradient-primary"
          class="mr-1"
          @click="saveData()"
        >
          บันทึก
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="ClearVal()"
        >
          ยกเลิก
        </b-button>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  // BForm,
  BRow,
  BCol,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBToggle,
  BFormCheckbox,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    // BForm,
    BRow,
    BCol,
    vSelect,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BCardBody,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      Data: {
        id: null,
        name: null,
        subtype: 0,
        group_id: null,
        Subhead: [
          {
            id: null,
            name: null,
          },
        ],
      },
      imagePreview: null,
      modalSubtype: false,
      modalShow: false,
      nextTodoId: 1,
      rolelist: [],
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'group_name', label: 'กลุ่มหวย' },
        { key: 'name', label: 'หวย' },
        { key: 'subtype', label: 'หวยย่อย' },
        { key: 'picture', label: 'รูปภาพ' },
        { key: 'actions', label: 'Action' },
      ],
      items: [],
      SubDetail: {},
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.GetLottoGroup()
    this.getData()
  },
  methods: {
    EncodeBase64(val) {
      const encodedText = btoa(val)
      return encodedText
    },
    handleFileUpload(event) {
      const file = event.target.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = e => {
        this.imagePreview = e.target.result
        // console.log(this.imagePreview)
      }
      reader.readAsDataURL(file)
    },
    openImageInput() {
      this.$refs.imageInput.click()
    },
    clearImage() {
      this.imagePreview = null
    },

    updateData(Val) {
      this.modalShow = true
      this.Data = Val
    },
    saveData() {
      this.Data.logo = this.imagePreview
      if (this.Data.id) {
        this.$http
          .put(
            `lottotypemange/${this.Data.id}`,
            this.Data,
          )
          .then(() => {
            this.Success('อัพเดตสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('lottotypemange', this.Data)
          .then(() => {
            this.Success('บันทึกสำเร็จ')
            this.getData()
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
      this.ClearVal()
    },
    repeateAgain() {
      this.Data.Subhead.push({
        id: null,
        name: null,
      })
    },
    removeItem(index) {
      this.Data.Subhead.splice(index, 1)
    },
    ClearVal() {
      this.modalShow = !this.modalShow
      this.Data = {
        id: null,
        name: null,
        subtype: 0,
        group_id: null,
        Subhead: [
          {
            id: null,
            name: null,
          },
        ],
      }
      this.clearImage()
    },
    getData() {
      this.$http
        .get('lottotypemange')
        .then(response => {
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    GetLottoGroup() {
      this.$http
        .get('lotto/group')
        .then(response => {
          this.rolelist = response.data
        })
        .catch(error => console.log(error))
    },
    deleteData(id) {
      this.$swal({
        title: 'ยืนยันการลบข้อมูล',
        text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .delete(`lottotypemange/${id}`)
            .then(response => {
              if (response.data) {
                this.getData()
                this.$swal({
                  icon: 'success',
                  title: 'ลบข้อมูลสำเร็จ',
                  text: 'ข้อมูลของคุณถูกลบแล้ว',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('ยกเลิก', '', 'success')
        }
      })
    },
    deleteSubData(id, index) {
      this.$swal({
        title: 'ยืนยันการลบข้อมูล',
        text: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .delete(`lottotypemange/destroySub/${id}`)
            .then(response => {
              this.Data.Subhead.splice(index, 1)
              if (response.data) {
                this.$swal({
                  icon: 'success',
                  title: 'ลบข้อมูลสำเร็จ',
                  text: 'ข้อมูลของคุณถูกลบแล้ว',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
            .catch(error => console.log(error))
        } else {
          this.$swal('ยกเลิก', '', 'success')
        }
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    DetailSub(Val) {
      this.modalSubtype = !this.modalSubtype
      this.SubDetail = Val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';

.form-item-section {
  background-color: $product-details-bg;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.upload-btn {
  border: 2px dashed #ccc;
  display: inline-block;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 150px;
  font-size: 18px;
  z-index: 0;
}

.upload-btn input[type="file"] {
  display: none;
}

.clear-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
}
</style>
